import axios from 'axios';
import { API_URL } from '../constants/constants';
// Exporta la función para ser reutilizada en distintos componentes
export const cotizarCascotes = async (lstaprsku, cp, colonia, idoficna) => {
    // console.log('Cotizare ah', cp, colonia)

    const sumaValorDeclarado = lstaprsku.reduce((acumulador, producto) => {
        const valorDeclarado = Number(producto.valor_declarado);
        return acumulador + (isNaN(valorDeclarado) ? 0 : valorDeclarado);
    }, 0);

    const storedCodigoPostal = cp;
    const storedNeighborhood = colonia;

    const nuevoArreglo = lstaprsku.map(producto => ({
        alto: producto.alto_cm,
        ancho: producto.ancho_cm,
        cantidad: producto.no_piezas,
        contenido: producto.contenido,
        largo: producto.largo_cm,
        peso: producto.peso
    }));

    const dataConIdOffice = {
        calle: '',
        noExterior: '',
        colonia: storedNeighborhood,
        ciudad: '',
        estado: '',
        ...(idoficna && { idOffice: idoficna, ocurre: "true" }),
        cp: storedCodigoPostal,
        valorDeclarado: 16000,
        paquetes: nuevoArreglo,
    };

    // console.log('enviare a cotizar', dataConIdOffice)

    try {
        const response = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/cotEnvio', dataConIdOffice);


        console.log('Respuesta', response.data)

        const total = response.data.TOTAL; if (total == null) { // Verifica tanto null como undefined
            // console.log("El total no está disponible en la respuesta.");
            const datosstreing= response.data
            return datosstreing; // Retorna la respuesta de la API para uso posterior
            // Manejar el caso donde TOTAL no está disponible
        } else {
            // console.log("El total es:", total);
            // Puedes retornar o hacer algo con el valor de TOTAL aquí
            return total;
        }


    } catch (error) {
        console.error("Error al enviar los datos con Ocurre directo:", error);
        throw error; // Propaga el error para manejarlo en el componente
    }
}

export const quoatationSkydropx = async (lstaprsku, cp, colonia, idoficna, email=null) => {
    // console.log('Cotizare ah', cp, colonia)

    const sumaValorDeclarado = lstaprsku.reduce((acumulador, producto) => {
        const valorDeclarado = Number(producto.valor_declarado);
        return acumulador + (isNaN(valorDeclarado) ? 0 : valorDeclarado);
    }, 0);

    const storedCodigoPostal = cp;
    const storedNeighborhood = colonia;

    const nuevoArreglo = lstaprsku.map(producto => ({
        alto: producto.alto_cm,
        ancho: producto.ancho_cm,
        cantidad: producto.no_piezas,
        contenido: producto.contenido,
        largo: producto.largo_cm,
        peso: producto.peso
    }));

    const dataConIdOffice = {
        calle: '',
        noExterior: '',
        colonia: storedNeighborhood,
        ciudad: '',
        estado: '',
        ...(idoficna && { idOffice: idoficna, ocurre: "true" }),
        cp: storedCodigoPostal,
        valorDeclarado: 16000,
        paquetes: nuevoArreglo,
    };

    const formatParcel = lstaprsku.map(producto => ({
        "address": {
            "postal_code": storedCodigoPostal,
            "state": "-",
            "city": "-",
            "colony": storedNeighborhood,
            "street": "Calle ejemplo",
            "email": email
        },
        "parcel": {
            "length": producto.largo_cm,
            "width": producto.ancho_cm,
            "height": producto.alto_cm,
            "weight": producto.peso,
            "quantity": producto.no_piezas
        },
        "carriers": []
      }));

    // console.log('enviare a cotizar', dataConIdOffice)

    try {
        const responseCastores = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/cotEnvio', dataConIdOffice);
        // Peticion a Skydropx
        const promises = formatParcel.map(element => {
            return axios.post(`${API_URL}/getQuotation`, element);
        });

        // console.log('Respuesta', response.data)

        const responses = await Promise.all(promises);
        let totalShipping = 0
        responses.forEach((response, index) => {
            console.log(`Respuesta para elemento ${index}:`, response.data.data?.total);
            if (response?.data?.data?.total) {
            totalShipping += response.data.data?.total * response.data.data?.quantity        
            console.log("Acumulado: ", totalShipping)  
            }
        });

        totalShipping =  parseFloat(totalShipping.toFixed(2))
        // Verificar con castores
        if (typeof responseCastores === "object" && responseCastores.data?.TOTAL && totalShipping == 0) {
            const totalCastores = responseCastores.data.TOTAL;
            totalShipping = totalCastores
          }

        const total = totalShipping; if (total == 0) { // Verifica tanto null como undefined
            // console.log("El total no está disponible en la respuesta.");
            const datosstreing= "Error al cotizar"
            return datosstreing; // Retorna la respuesta de la API para uso posterior
            // Manejar el caso donde TOTAL no está disponible
        } else {
            // console.log("El total es:", total);
            // Puedes retornar o hacer algo con el valor de TOTAL aquí
            return total;
        }


    } catch (error) {
        console.error("Error al enviar los datos con Ocurre directo:", error);
        throw error; // Propaga el error para manejarlo en el componente
    }
}
